import {styled} from "@mui/material/styles";
import {Link} from "gatsby";

export const MyLink = styled(Link)(({ theme }) => ({
  color: '#fff',
  textDecoration: 'none',
}));

export const MyLinkLight = styled(Link)(({ theme }) => ({
  color: '#000',
  textDecoration: 'none',
  padding: 5,
  paddingBottom: 10,
  paddingTop: 0,
}));

export const MyLinkLightIn = styled(Link)(({ theme }) => ({
  color: '#000',
  textDecoration: 'none',
  // padding: 5,
  paddingTop: 0,
  flex: 1
}));

export const MyLinkIn = styled(Link)(({ theme }) => ({
  color: '#fff',
  textDecoration: 'none',
  // padding: 5,
  paddingTop: 0,
  flex: 1
}));
