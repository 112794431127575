const productsRoot = '/products'


export default [
  {
    link: productsRoot + '/hardware',
    title: '传感器硬件',
    subTitle: 'Hardware sensors',
    width: 4,
  },
  {
    link: productsRoot + '/wind',
    title: '超分辨率模拟风系统',
    subTitle: 'Hyper resolution wind simulation system',
    width: 4,
  },
  {
    link: productsRoot + '/city3d',
    title: '三维城市气象仿真系统',
    subTitle: '3D city weather simulation system',
    width: 4,
  },
  {
    link: productsRoot + '/encryption',
    title: '数据加密解决方案',
    subTitle: 'Data encryption solution',
    width: 4,
  },
  {
    link: productsRoot + '/radar',
    title: '实时雷达三维渲染系统',
    subTitle: 'Real time 3D radar rendering system',
    width: 4,
  },
  {
    link: productsRoot + '/lng',
    title: 'LNG服务',
    subTitle: 'LNG services',
    width: 2,
  },
  {
    link: productsRoot + '/robot',
    title: '微信机器人',
    subTitle: 'Wechat robot',
    width: 2,
  },
  {
    link: productsRoot + '/edge',
    title: '计算机视觉边缘计算',
    subTitle: 'Computer Vision edge computing',
    width: 4,
  },
]
