import * as React from "react"
import {Link} from "gatsby";
import {styled, alpha, useTheme, createTheme, ThemeProvider} from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';

import ClickAwayListener from "@mui/material/ClickAwayListener";

import Grid from '@mui/material/Grid'
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List/List";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import Collapse from "@mui/material/Collapse/Collapse";
import Divider from "@mui/material/Divider/Divider";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

import { MyLink, MyLinkLight, MyLinkLightIn } from '../Common/Links'
import { DrawerSubTitle, DrawerTitle, MyTypography, MyTypography2 } from '../Common/Base'

import menuData from './menuData'

const themeLight = createTheme({
  palette: {
    mode: 'light',
    text: {
      primary: '#000000',
    }
  }
});

const Logo = styled('img')(({ theme }) => ({
  width: 123,
  height: 50,
  [theme.breakpoints.down('sm')]: {
    width: 69,
    height: 28,
  },
}));
const MyToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: '#000',
  [theme.breakpoints.down('sm')]: {
    height: 70,
  },
  height: 88,
}));


const HeaderPaper = styled(Paper)(({ theme }) => ({
  background: '#fff',
  borderRadius: 0,
  padding: 15,
  paddingLeft: 40,
  paddingRight: 0,
  width: '100vw',
  maxWidth: theme.breakpoints.values['md'],
}));

const MyDrawer = styled(Drawer)(({theme}) => ({
  top: 70,
  '& .MuiDrawer-paper': {
    paddingTop: 25,
    paddingBottom: 35,
    top: 70
  },
}));

export const Header = (props) => {
  const { location } = props;
  const [productListShow, setProductListShow] = React.useState(false);
  const theme = useTheme();
  const headRef = React.useRef();
  const headTimeRef = React.useRef();

  const [menuOpen, setMenuOpen] = React.useState();
  const [drawerService, setDrawerService] = React.useState();

  const handleProductIn = () => {
    if(headTimeRef.current) {
      clearTimeout(headTimeRef.current);
      headTimeRef.current = null;
    }
    setProductListShow(true);
  };
  const handleProductOut = () => {
    headTimeRef.current = setTimeout(() => {
      setProductListShow(false)
    }, 200);
  };

  const handlePopoverClose = () => {
    setProductListShow(false)
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const togDrawerService = () => {
    setDrawerService(!drawerService);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <MyToolbar ref={headRef}>
          <Container maxWidth="md" style={{paddingLeft: 0, paddingRight: 0}}>
            <Stack direction="row" spacing={10} justifyContent="space-between" alignItems="center">
              <Link to="/">
                <Logo src={require('../../images/common/logo.png').default} alt="赑玄格" />
              </Link>
              <Stack
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', sm: 'flex' },
                  pt: 2,
                }}
                direction="row"
                spacing={0}
                justifyContent="space-around"
                alignItems="center"
              >
                <MyLink to={'/'}>
                  <MyTypography variant="subtitle1">首页</MyTypography>
                  <MyTypography2 variant="caption">Home</MyTypography2>
                </MyLink>
                <ClickAwayListener onClickAway={handlePopoverClose}>
                  <Box
                    aria-haspopup="true"
                    onClick={handleProductIn}
                    style={{ cursor: 'pointer'}}
                  >
                    <MyTypography
                      variant="subtitle1"
                    >产品服务</MyTypography>
                    <MyTypography2
                      variant="caption"
                    >Product services</MyTypography2>
                  </Box>
                </ClickAwayListener>
                <MyLink to={'/scienceqa'}>
                  <MyTypography variant="subtitle1">科学问答</MyTypography>
                  <MyTypography2 variant="caption">Science Q&A</MyTypography2>
                </MyLink>
                <MyLink to={'/story'}>
                  <MyTypography variant="subtitle1">关于我们</MyTypography>
                  <MyTypography2 variant="caption">Our story</MyTypography2>
                </MyLink>
                <MyLink to={'/contact'}>
                  <MyTypography variant="subtitle1">联系我们</MyTypography>
                  <MyTypography2 variant="caption">Contact us</MyTypography2>
                </MyLink>
              </Stack>
              <Box sx={{display: { xs: 'block', sm: 'none' } }}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ }}
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Stack>
          </Container>
        </MyToolbar>
      </AppBar>

      <Popper
        open={productListShow}
        anchorEl={headRef.current}
        onClose={handlePopoverClose}
        placement="bottom"
        style={{
          zIndex: 999,
        }}
      >
        <ThemeProvider theme={themeLight}>
          <HeaderPaper>
            <Grid container spacing={2}>
              {
                menuData.map((item, index) => {
                  return(
                    <Grid key={'menu-md-' + index} item xs={4}>
                      <MyLinkLight to={item.link}>
                        <MyTypography variant="subtitle1">{item.title}</MyTypography>
                        <MyTypography2 variant="caption" gutterBottom>{item.subTitle}</MyTypography2>
                      </MyLinkLight>
                    </Grid>
                  )
                })
              }
            </Grid>
          </HeaderPaper>
        </ThemeProvider>
      </Popper>
      <ThemeProvider theme={themeLight}>
        <MyDrawer
          anchor={'top'}
          open={menuOpen}
          onClose={handleMenuClose}
          transitionDuration={0}
        >
          <List
            sx={{ ml: 5, mr: 5 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton>
              <MyLinkLightIn to={'/'}>
                <DrawerTitle>首页</DrawerTitle>
                <DrawerSubTitle>Home</DrawerSubTitle>
              </MyLinkLightIn>
            </ListItemButton>
            <Divider component="li" />
            <ListItemButton onClick={togDrawerService}>
              <Box sx={{flex: 1}}>
                <DrawerTitle>产品服务</DrawerTitle>
                <DrawerSubTitle>Products services</DrawerSubTitle>
              </Box>
              {drawerService ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Divider component="li" />
            <Collapse in={drawerService} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {
                  menuData.map((item, index) => {
                    return(
                      <React.Fragment key={'menu-xs-' + index}>
                        <ListItemButton sx={{ pl: 4 }}>
                          <MyLinkLightIn to={item.link}>
                            <DrawerTitle>{item.title}</DrawerTitle>
                            <DrawerSubTitle>{item.subTitle}</DrawerSubTitle>
                          </MyLinkLightIn>
                        </ListItemButton>
                        {
                          index === menuData.length - 1 ? null : (
                            <Divider sx={{ ml: 4 }} component="li" />
                          )
                        }
                      </React.Fragment>
                    )
                  })
                }
              </List>
              <Divider component="li" />
            </Collapse>
            <ListItemButton>
              <MyLinkLightIn to={'/scienceqa'}>
                <DrawerTitle>科学问答</DrawerTitle>
                <DrawerSubTitle>Science Q&A</DrawerSubTitle>
              </MyLinkLightIn>
            </ListItemButton>
            <Divider component="li" />
            <ListItemButton>
              <MyLinkLightIn to={'/story'}>
                <DrawerTitle>关于我们</DrawerTitle>
                <DrawerSubTitle>Our story</DrawerSubTitle>
              </MyLinkLightIn>
            </ListItemButton>
            <Divider component="li" />
            <ListItemButton>
              <MyLinkLightIn to={'/contact'}>
                <DrawerTitle>联系我们</DrawerTitle>
                <DrawerSubTitle>Contact us</DrawerSubTitle>
              </MyLinkLightIn>
            </ListItemButton>
            <Divider component="li" />
          </List>
        </MyDrawer>
      </ThemeProvider>
    </Box>
  )
}

export default { Header }
