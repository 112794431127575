import * as React from 'react'
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {Link} from "gatsby";
import Box from "@mui/material/Box";


export const MyTypography = styled(Typography)(({ theme }) => ({
  lineHeight: 1
}));
export const MyTypography2 = styled(Typography)(({ theme }) => ({
  lineHeight: 1,
  opacity: 0.7,
  whiteSpace: 'nowrap'
}));

export const DrawerTitle = styled('span')(({theme}) => ({
  fontSize: '14px',
}));
export const DrawerSubTitle = styled('span')(({theme}) => ({
  fontSize: '12px',
  marginLeft: 5,
  opacity: 0.5,
}));


export const BlackBack = styled('div')(({theme}) => {
  return {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 100,
    backgroundImage: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1), rgba(0,0,0,1))`,
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      height: 150,
    },
    [theme.breakpoints.up('md')]: {
      height: 200,
    }
  }
});

export const Box4Title = styled(Typography)(({theme}) => {
  return {
    color: '#1BAFBF',
    fontSize: '16px',
    fontWeight: 'bold',
    // whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3.5vw',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "38px",
    }
  }
});

export const TitleBox = styled(Typography)(({theme}) => {
  return {
    color: '#1BAFBF',
    fontSize: '2vw',
    [theme.breakpoints.up('lg')]: {
      fontSize: "24px",
    }
  }
});
export const TitleEnBox = styled(Typography)(({theme}) => {
  return {
    color: '#1BAFBF',
    fontSize: '1.8vw',
    [theme.breakpoints.up('lg')]: {
      fontSize: "20px",
    }
  }
});

export const SubTitleBox = styled(Typography)(({theme}) => {
  return {
    color: '#707070',
    fontSize: '1.6vw',
    [theme.breakpoints.up('lg')]: {
      fontSize: "18px",
    }
  }
});

export const SubTitleWhiteBox = styled(Typography)(({theme}) => {
  return {
    color: '#FFF',
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5vw',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "24px",
    }
  }
});

export const SubTitleEnBox = styled(Typography)(({theme}) => {
  return {
    color: '#9BA2B2',
    fontSize: '12px',
    lineHeight: 1.4,
    [theme.breakpoints.up('sm')]: {
      fontSize: '2vw',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "20px",
    }
  }
});

export const ProductContact = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        pb: {
          xs: 2,
          sm: 4,
          md: 8,
          lg: 12,
        },
        pt: {
          xs: 2,
          sm: 4,
          md: 8,
          lg: 12,
        }
      }}
    >
      <BackBox
        style={{
          backgroundImage: `url(${require('../../images/products/hardware/17.png').default})`,
          height: '25vw',
          minHeight: '300px'
        }}
      >
        <CennterBox
          sx={{
            height: '25vw',
            minHeight: '300px'
          }}
        >
          <SubTitleWhiteBox
            variant="h5"
            sx={{
              mb: 2
            }}
          >
            咨询我们了解更多项目相关内容
          </SubTitleWhiteBox>
          <SubTitleEnBox gutterBottom variant="h6">
            Talk to our experts for more information<br /><br />
          </SubTitleEnBox>
          <LinkBox2 to={'/contact'}>
            了解更多 Contact Us
          </LinkBox2>
        </CennterBox>
      </BackBox>
    </Container>
  )
};

export const BackBox = styled(Box)(() => ({
  position: 'relative',
  overflow: 'hidden',
  // minHeight: '45vw',
  backgroundSize: 'cover',
  backgroundPosition: 'right center',
  backgroundRepeat: 'no-repeat'
}));

const LinkBox2 = styled(Link)(({theme}) => {
  return {
    display: 'flex',
    marginTop: 25,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1BAFBF',
    color: '#fff',
    textDecoration: 'none',
    width: 200,
    height: 40,
    borderRadius: 20,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      width: 200,
      height: 30,
      borderRadius: 35,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      width: 400,
      height: 64,
      borderRadius: 32,
    }
  }
});

export const CennterBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));


export const MyImg = styled('img')(() => ({
  verticalAlign: 'bottom'
}));
