import * as React from "react"

import Box from '@mui/material/Box'

import {styled, alpha, useTheme, createTheme, ThemeProvider} from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles'
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import footerImage from '../../images/common/footer.jpg'

import { MyLink, MyLinkIn, } from '../Common/Links'
import { DrawerSubTitle, DrawerTitle, MyTypography, MyTypography2 } from '../Common/Base'
import Divider from "@mui/material/Divider";
import menuData from "../Header/menuData";
import ListItemButton from "@mui/material/ListItemButton";
import bxgImg from '../../images/common/bxg.png'
import List from "@mui/material/List/List";

const RootBox = styled(Stack)(({theme, open}) => {
  return {
    height: 960,
    [theme.breakpoints.down('sm')]: {
      height: 660
    },
    backgroundColor: '#000',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundImage: `url(${footerImage})`,
    backgroundSize: 'cover',
    display: 'flex',
  }

})

export const Footer = () => {
  return (
    <React.Fragment>
      <RootBox>
        <Container
          maxWidth="lg"
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Stack
            spacing={4}
            justifyContent="center"
            direction="row"
            alignItems="flex-start"
            sx={{
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                },
                mt: 1
              }}
            >
              <MyLink to={'/'}>
                <MyTypography variant="subtitle1">首页</MyTypography>
                <MyTypography2 variant="caption" gutterBottom>Home</MyTypography2>
              </MyLink>
            </Box>
            <Stack
              spacing={{
                xs: 1,
                sm: 3,
              }}
              justifyContent="space-around"
              direction={{
                xs: 'column',
                sm: 'row',
              }}
            >
              <Box
                sx={{display: {
                    xs: 'none',
                    sm: 'block'
                  }}}
              >
                <MyLink to={'/'}>
                  <MyTypography variant="subtitle1">首页</MyTypography>
                  <MyTypography2 variant="caption" gutterBottom>Home</MyTypography2>
                </MyLink>
              </Box>
              <Box
                style={{paddingBottom: 5, cursor: 'pointer'}}
              >
                <MyTypography
                  variant="subtitle1"
                >产品服务</MyTypography>
                <MyTypography2
                  variant="caption"
                  gutterBottom
                >Product services</MyTypography2>
              </Box>
              <Divider sx={{
                display: {
                  xs: 'none',
                  sm: 'block'
                }
              }} orientation="vertical" flexItem />
              <Divider sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                }
              }} flexItem />
              <List component="div" disablePadding>
                {
                  menuData.map((item, index) => {
                    return(
                      <ListItemButton key={'menu-foot-' + index} sx={{
                        p: {
                          xs: '3px',
                          sm: 1,
                        },
                        pl: {
                          xs: 0,
                          sm: 4
                        },
                      }}>
                        <MyLinkIn to={item.link}>
                          <DrawerTitle>{item.title}</DrawerTitle>
                          <DrawerSubTitle>{item.subTitle}</DrawerSubTitle>
                        </MyLinkIn>
                      </ListItemButton>
                    )
                  })
                }
              </List>
              <Divider sx={{
                display: {
                  xs: 'block',
                  sm: 'none'
                }
              }} flexItem />
              <Stack
                spacing={2}
                direction={{
                  xs: 'row',
                  sm: 'column',
                }}
              >
                <MyLink to={'/scienceqa'}>
                  <MyTypography variant="subtitle1">科学问答</MyTypography>
                  <MyTypography2 variant="caption" gutterBottom>Science Q&A</MyTypography2>
                </MyLink>
                <MyLink to={'/story'}>
                  <MyTypography variant="subtitle1">关于我们</MyTypography>
                  <MyTypography2 variant="caption" gutterBottom>Our story</MyTypography2>
                </MyLink>
                <MyLink to={'/contact'}>
                  <MyTypography variant="subtitle1">联系我们</MyTypography>
                  <MyTypography2 variant="caption" gutterBottom>Contact us</MyTypography2>
                </MyLink>
              </Stack>
            </Stack>
            <Box sx={{display: {
                xs: 'none',
                md: 'block'
              }}}>
              <img alt="" src={bxgImg} width={255} />
            </Box>
          </Stack>
        </Container>
        <Box
          sx={{
            height: 30,
            display: {
              sm: 'flex',
              xs: 'none',
            },
            fontSize: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'rgba(0,0,0,.6)'
          }}
        >
          ©2021～2022 深圳市赑玄阁科技有限公司 粤ICP备15012844号-1
        </Box>
      </RootBox>
      <Box
        sx={{
          height: 25,
          backgroundColor: '#000',
          display: {
            sm: 'none',
            xs: 'flex',
          },
          fontSize: '12px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        ©2021～2022 深圳市赑玄阁科技有限公司 <a style={{color: '#fff'}} href="https://beian.miit.gov.cn/">粤ICP备15012844号-1</a>
      </Box>
      <div dangerouslySetInnerHTML={{__html: '<div style="display: none;"><script type="text/javascript" src="https://v1.cnzz.com/z_stat.php?id=1280627981&web_id=1280627981"></script></div>'}} />
    </React.Fragment>
  );
};
