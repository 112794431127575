import * as React from "react";
import Helmet from "react-helmet";

import CssBaseline from '@mui/material/CssBaseline';
import { styled, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import {Link} from "gatsby";
import {useState} from "react";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import md5 from 'md5';

// import "../styles";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import Box from "@mui/material/Box";
import {useEffect} from "react";

let evidence = 'eaa86193f2942b85aa1f712a516f4bd9'
const Logoimg = styled('img')(({ theme }) => ({
  width: 123,
  height: 50,
  [theme.breakpoints.down('sm')]: {
    width: 69,
    height: 28,
  },
}));
const IndexInput = styled(TextField)(({theme}) => {
  return {
    borderRadius: '8px',
    background: '#F5F5F5',
    width:'20vw',
    border:'none',
    '& .css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input':{
      color:'#ABABAB !important' 

    },
    '& .MuiInputBase-input':{
      color:'#ABABAB !important' 
    }
  }
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000',
    },
    secondary: {
      main: "#04D94F",
    },
  },
});

const TemplateWrapper = (props) => {
  const { location } = props;
  const theme = useTheme();
  const [showLogin, setShowLogin] = React.useState(true);
  const [inputValue, setInputValue] = useState('');
  const [openData, setOpen] = React.useState(false);

  useEffect(() => {
    let proof =  sessionStorage.getItem('evidence')
    if(proof && proof === evidence){
      setShowLogin(false)
    }else{
      setShowLogin(true)
    }
  },[])
  const handleInputChange = (key) => (event) => {
    setInputValue(event.target.value);
  };
  const handleKeySubmit= (event) => {
    if(event.key=== 'Enter'){
      handleSubmit()
    }
  }
  const handleSubmit = async () => {
    if(!inputValue) {
      setOpen({
        type: 'error',
        message: '请输入联系方式！'
      });
      
    }else if(md5(inputValue) === evidence){
      sessionStorage.setItem('evidence', evidence)
      setShowLogin(false)
    }else{
      setOpen({
        type: 'error',
        message: '密码错误！'
      });
    }
    setTimeout(()=>{
      setOpen(false);
    },3000)
    return;

  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    try {
      document.oncontextmenu = function(){ return false; };
      // 禁止文字选择
      document.onselectstart = function(){ return false; };
      // 禁止复制
      document.oncopy = function(){ return false; };
      // 禁止剪切
      document.oncut = function(){ return false; };
      // 禁止粘贴
      document.onpaste = function(){ return false; };
    } catch (e) {
    }

  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="shortcut icon" href={require('../images/favicon.ico').default} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-touch-fullscreen" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="full-screen" content="yes" />

        <link rel="icon" type="image/x-icon" sizes="16x16" href={require('../images/favicon-16x16.png').default} />
        <link rel="icon" type="image/x-icon" sizes="32x32" href={require('../images/favicon-32x32.png').default} />

        <link rel="icon" sizes="192x192" href={require('../images/android-chrome-192x192.png').default} />
        <link rel="icon" sizes="512x512" href={require('../images/android-chrome-512x512.png').default} />

        <link rel="apple-touch-icon-precomposed" sizes="180x180" href={require('../images/android-chrome-180.png').default} />

        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="msapplication-TileImage" content={require('../images/android-chrome-144.png').default} />
      </Helmet>
      <Snackbar
        open={!!openData}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={openData.type || 'info'} sx={{ width: '100%' }}>
          {openData.message}
        </Alert>
      </Snackbar>
      <ThemeProvider theme={darkTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {
        showLogin ? (
          <Box style={{display:'block', width:'100vw',height:'100vh',position:'relative'}}>
              <Box style={{display:'flex'}}>
                <img style={{width:'100vw',height:'100vh'}} src={require('../images/common/bg.png').default} alt="赑玄格"></img>

              </Box>
              <Box style={{position:'absolute',left:'0',right:0,top:0,bottom:0, display:'flex',justifyContent: 'center',alignItems: 'center',paddingBottom:'10vh'}}>
                <Box style={{ display:'flex',justifyContent: 'center',alignItems: 'center', flexDirection: 'column', gap:'16px' }}>
                  <Logoimg src={require('../images/common/loadLogo.png').default} alt="赑玄格" />
              
                  <IndexInput 
                    type="password"
                    placeholder="请输入神秘符号"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // multiline
                    // rows={1}
                    // fullWidth
                    // sx={{mb: 2}}
                    value={inputValue|| ''}
                    onChange={handleInputChange()}
                    onKeyDown={handleKeySubmit}
                  />
                  <Box style={{textShadow: '0px 4px 4px rgba(0, 0, 0, 0.50',fontWeight:'600'}}>输入神秘符号</Box>
                  <Button onClick={handleSubmit} style={{padding:'6px 32px', background:'#212121',color:'rgba(255, 255, 255, 0.90)',borderRadius:'8px',boxShadow:' 0px 0px 4px 0px rgba(117, 117, 117, 0.50)'}} variant="contained">解锁</Button>
                </Box>
              </Box>
          </Box>
        ):(
          <Box>
            <Header location={location} />
            <Box sx={{
              minHeight: {
                xs: 'calc(100vh - 70px - 435px)',
                sm: 'calc(100vh - 88px - 760px)',
              }
            }}>
              {props.children}
            </Box>
            <Footer location={location} />
          </Box>
      )}
       
      </ThemeProvider>

    </React.Fragment>
  ) 
};

export default TemplateWrapper;
